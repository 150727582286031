<script>
import TicketIcon from "@/assets/images/facilities/tickets.svg";
import ProductIcon from "@/assets/images/facilities/products.svg";
import FacilityIcon from "@/assets/images/facilities/facility.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import TimeRatesIcon from "@/assets/images/facilities/time-rates.svg";
import ConfigIcon from "@/assets/images/facilities/config.svg";
import TimingIcon from "@/assets/images/facilities/timing.svg";
import VehicleIcon from "@/assets/images/facilities/vehicles.svg";
import TransponderIcon from "@/assets/images/facilities/transponder.svg";
import LoopIcon from "@/assets/images/facilities/loops.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";

export default {
  name: "FacilitiesTabs",
  components: {
    AddIcon,
    ConfigIcon,
    TimingIcon,
    TimeRatesIcon,
    SvgIcon,
    FacilityIcon,
    ProductIcon,
    TicketIcon,
    VehicleIcon,
    TransponderIcon,
    LoopIcon,
  },
  props: {
    venueServiceId: {
      type: Number,
      default: null,
    },
    venueServices: {
      type: Array,
      default: () => [],
    },
    configurations: {
      type: Object,
      default: () => {},
    },
    showAddFacility: {
      type: Boolean,
      default: false,
    },
    showAddCategory: {
      type: Boolean,
      default: false,
    },
    showAddLoop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localVenueServiceId: {
      get() {
        return this.venueServiceId;
      },
      set(value) {
        this.$emit("update:venueServiceId", value);
      },
    },
    tabs() {
      return [
        {
          name: "Facility",
          icon: FacilityIcon,
          path: ["FacilityPerService", "Facilities"],
          data: btoa(
            JSON.stringify({
              venue_service_id: this.venueServiceId,
              service: this.venueServices.find(
                (item) => item.venue_service_id == this.venueServiceId
              )?.name,
            })
          ),
          permission: true,
        },
        {
          name: "Tickets",
          icon: TicketIcon,
          path: ["TickerRentalProductPerService"],
          data: btoa(
            JSON.stringify({
              venue_service_id: this.venueServiceId,
              is_ticket: 1,
            })
          ),
          is_ticket: 1,
          permission:
            (this.configurations.per_capacity == 1 ||
              this.configurations.mixed_config == true) &&
            this.checkReadPermission(this.$modules.facility.timeRates.slug),
        },
        {
          name: "Time Rates",
          icon: TimeRatesIcon,
          path: ["TickerRentalProductPerService"],
          data: btoa(
            JSON.stringify({
              venue_service_id: this.venueServiceId,
              is_ticket: 0,
            })
          ),
          is_ticket: 0,
          permission:
            (this.configurations.per_capacity == 0 ||
              this.configurations.mixed_config == true) &&
            this.checkReadPermission(this.$modules.facility.timeRates.slug),
        },
        {
          name: "Products",
          icon: ProductIcon,
          path: ["FacilityProductsPerService"],
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
          permission: this.checkReadPermission(
            this.$modules.facility.addonProducts.slug
          ),
        },
        {
          name: "Vehicles",
          icon: VehicleIcon,
          path: ["VehiclePerService"],
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
          permission: this.configurations.is_my_lap_enabled,
        },
        {
          name: "Transponder",
          icon: TransponderIcon,
          path: ["TransponderPerService"],
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
          permission: this.configurations.is_my_lap_enabled,
        },
        {
          name: "Loops",
          icon: LoopIcon,
          path: ["LoopsPerService"],
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
          permission: this.configurations.is_my_lap_enabled,
        },
        {
          name: "Timing",
          icon: TimingIcon,
          path: ["FacilityTiming"],
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
          permission: this.checkReadPermission(
            this.$modules.facility.configuration.slug
          ),
        },
        {
          name: "Configuration",
          icon: ConfigIcon,
          path: ["FacilityConfiguration"],
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
          permission: this.checkReadPermission(
            this.$modules.facility.configuration.slug
          ),
        },
      ];
    },
    paramData() {
      const pData = this.$route.params.data;
      if (pData) {
        return JSON.parse(atob(pData));
      }
      return {};
    },
  },
  methods: {
    gotoPage(tab) {
      if (!this.localVenueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: tab.path[0],
        params: {
          data: tab.data,
        },
      });
    },
    gotToFacilityForm() {
      if (!this.localVenueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityForm",
        params: {
          data: btoa(
            JSON.stringify({
              venue_service_id: this.venueServiceId,
              service: this.venueServices.find(
                (item) => item.venue_service_id == this.venueServiceId
              ).name,
            })
          ),
        },
      });
    },
  },
};
</script>

<template>
  <v-row dense>
    <v-col cols="12" lg="12" xl="12">
      <div class="d-flex justify-space-between">
        <div class="d-flex bordered qp-tab-nav">
          <div class="nv_item">
            <v-select
              v-model="localVenueServiceId"
              :disabled="venueServices.length === 0"
              :height="46"
              :items="venueServices"
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="rgba(79, 174, 175, 0.1)"
              class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
              color="#4FAEAF"
              dense
              hide-details
              item-color="#4FAEAF"
              item-text="name"
              item-value="venue_service_id"
              outlined
              placeholder="Service"
              style="width: 140px"
              v-on:change="$emit('serviceChange')"
            ></v-select>
          </div>
          <div class="d-flex p-2 tabs">
            <template v-for="(tab, index) in tabs">
              <div
                v-if="tab.permission"
                v-bind:key="tab.name"
                :class="{ tab: index < tabs.length - 1 }"
                class="nv_item d-flex pointer"
                @click="gotoPage(tab)"
              >
                <SvgIcon
                  :class="{
                    'qp-tab-nav-is-active':
                      tab.path.includes($route.name) &&
                      (tab.is_ticket === undefined ||
                        tab.is_ticket === paramData.is_ticket),
                    'qp-tab-nav':
                      !tab.path.includes($route.name) &&
                      (tab.is_ticket === undefined ||
                        tab.is_ticket === paramData.is_ticket),
                  }"
                  :text="tab.name"
                  class="text-thin-gray"
                >
                  <template v-slot:icon>
                    <component :is="tab.icon" :component="tab.icon" />
                  </template>
                </SvgIcon>
              </div>
            </template>
          </div>
        </div>

        <div v-if="showAddFacility" class="d-flex gap-x-2">
          <v-btn
            v-if="checkWritePermission($modules.facility.management.slug)"
            class="white--text blue-color ml-1"
            color=" darken-1"
            height="48"
            text
            @click="gotToFacilityForm()"
          >
            <AddIcon />
            <span class="ml-1">Add Facility</span>
          </v-btn>
        </div>
        <div v-if="showAddCategory" class="d-flex gap-x-2">
          <v-btn
            v-if="checkWritePermission($modules.facility.management.slug)"
            class="white--text blue-color ml-1"
            color=" darken-1"
            height="48"
            text
            @click="$emit('showCategoryDialog', true)"
          >
            <AddIcon />
            <span class="ml-1"> Category</span>
          </v-btn>
        </div>
        <div v-if="showAddLoop" class="d-flex gap-x-2">
          <v-btn
            v-if="checkWritePermission($modules.facility.management.slug)"
            class="white--text blue-color ml-1"
            color=" darken-1"
            height="48"
            text
            @click="$emit('showLoopDialog', true)"
          >
            <AddIcon />
            <span class="ml-1"> Add Loop</span>
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
</style>