<template>
  <v-container fluid>
    <FacilitiesTabs
        :configurations="configurations"
        :venue-service-id="venueServiceId"
        :venue-services="venueServices"
        @serviceChange="goToTimings"
        @update:venueServiceId="(value)=>venueServiceId = value"
    />
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <v-container fluid style="max-width: 85%">
      <v-form ref="conf_form">
      <div class="text-base font-semibold black-text ml-1 mt-6">
        Default Timing
      </div>
      <v-card
          class="rounded-2 shadow-0 bordered mt-2"
          outlined
      >
        <v-card-text>
          <v-row dense>
            <v-col md="3">
              <label for="">Name*</label>
              <v-text-field
                  v-model="defaultTiming.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  background-color="#fff"
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
                  outlined
                  required
              ></v-text-field>
            </v-col>
            <v-col lg="4">
              <label for="">Choose Days Applicable</label>
              <v-autocomplete
                  v-model="defaultTiming.weekdays"
                  :items="weekdays"
                  :rules="[(v) => v.length > 0 || 'Weekdays is required']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="bit_value"
                  multiple
                  outlined
                  validate-on-blur
              >
                <template
                    v-if="weekdays.length == defaultTiming.weekdays.length"
                    v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Days</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, {{ item.name }}</span>
                  <span v-if="index == 2">, ...</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle()">
                    <v-list-item-action>
                      <v-icon :color="defaultTiming.weekdays.length > 0 ? 'indigo darken-4' : ''">{{ icon() }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

              </v-autocomplete>
            </v-col>
            <v-col lg="2">
              <label for="">From*</label>
              <v-select
                  v-model="defaultTiming.start_time"
                  :items="timingData.slice(0, (timingData.length -1))"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'From time is required']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="text"
                  item-value="value"
                  outlined
                  required
              ></v-select>
            </v-col>
            <v-col lg="2">
              <label for="">To*</label>

              <v-select
                  v-model="defaultTiming.end_time"
                  :items="timingData.slice(1)"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'To time is required']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="text"
                  item-value="value"
                  outlined
                  required
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div v-if="
          checkWritePermission($modules.facility.management.slug) &&
            venueServices.length
        " class="text-base font-semibold black-text ml-1 mt-6">Timing Templates
      </div>
      <v-card v-if="
          checkWritePermission($modules.facility.management.slug) &&
            venueServices.length
        "
              class="rounded-2 shadow-0 bordered mt-2"
              outlined
      >
        <v-card-text>
          <v-row v-for="(timings, k) in timingTemplates" :key="k"
                 dense>
            <v-col md="3">
              <label for="">Name*</label>
              <v-text-field
                  v-model="timings.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  background-color="#fff"
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
                  outlined
                  required
              ></v-text-field>
            </v-col>
            <v-col lg="4">
              <label for="">Choose Days Applicable</label>
              <v-autocomplete
                  v-model="timings.weekdays"
                  :items="weekdays"
                  :rules="[(v) => v.length > 0 || 'Weekdays is required']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="bit_value"
                  multiple
                  outlined
                  validate-on-blur
              >
                <template
                    v-if="weekdays.length == timings.weekdays.length"
                    v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Days</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, {{ item.name }}</span>
                  <span v-if="index == 2">, ...</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleTemplate(k)">
                    <v-list-item-action>
                      <v-icon :color="defaultTiming.weekdays.length > 0 ? 'indigo darken-4' : ''">{{ iconTemplate(k) }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

              </v-autocomplete>
            </v-col>
            <v-col lg="2">
              <label for="">From*</label>
              <v-select
                  v-model="timings.start_time"
                  :items="timingData.slice(0, (timingData.length -1))"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'From time is required']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="text"
                  item-value="value"
                  outlined
                  required
              ></v-select>
            </v-col>
            <v-col lg="2">
              <label for="">To*</label>

              <v-select
                  v-model="timings.end_time"
                  :items="timingData.slice(1)"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'To time is required']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="text"
                  item-value="value"
                  outlined
                  required
              ></v-select>
            </v-col>
            <v-col lg="1">
              <v-btn v-if="timingTemplates.length > 1"
                     class="mt-6"
                     color="red"
                     dark
                     fab
                     icon
                     x-small
                     @click="deleteTimingTemplate(k)"
              >
                <DeleteIcon/>
              </v-btn>
            </v-col>

          </v-row>
          <v-row class="mt-4" dense>
            <v-col>
              <p class="text-base text-neon text-underline font-semibold pointer" @click="
                    timingTemplates.push({
                      name: null,
                      weekdays: [],
                      end_time: null,
                    })
                  ">+Add Timing Template</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="ma-2 white--text blue-color"
            height="45"
            text
            @click="saveTimings"
        >Save
        </v-btn
        >
      </v-card-actions>
      </v-form>
    </v-container>
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
  </v-container>
</template>
<script>
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";
import moment from "moment";

export default {
  name: "FacilityTiming",
  components: {DeleteIcon, FacilitiesTabs},
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService.filter((service) => service.name != "POS");
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  data() {
    return {
      timingTemplates: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      deleted_timing_templates: [],
      defaultTiming: {
        name: null,
        weekdays: [],
        start_time: null,
        end_time: null,
      },
      timingData: [],
      timeIncrement: 60,
      configurations: {
        field_configurations: [],
        venue_service_documents: [],
        venue_service_configuration: {tnc_enable: 0, tnc: ""},
        deleted_service_documents: [],
        game_formations: [{name: ""}],
        facility_surface: [{}],
        per_capacity: 0,
        qube_configuration: null
      },
      venueServiceId: null,
    }
  },
  mounted() {
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((res) => {
        if (res.length) {
          this.checkVenueService();
        }
      });
    } else {
      this.checkVenueService();
    }
  },
  methods: {
    generateTiming() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.timingData = [];
      this.timingData.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.timingData.push(data);
          hour = 24;
        } else {
          this.timingData.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
    async initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        await this.$store
            .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
            .then((response) => {
              if (response.status == 200) {
                this.configurations = this.$store.getters.getConfigurationByVenueServiceId(
                    this.venueServiceId
                );
                this.timeIncrement =
                    this.configurations.time_increment != null ? this.configurations.time_increment : 60;
              }
            });
      } else {
        this.configurations = this.$store.getters.getConfigurationByVenueServiceId(
            this.venueServiceId
        );

        this.timeIncrement =
            this.configurations.time_increment != null ? this.configurations.time_increment : 60;
      }

    },
    checkVenueService() {
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceId = data.venue_service_id;
      } else {
        if (this.$store.getters.getSportsService.length) {
          this.venueServiceId = this.$store.getters.getSportsService[0].venue_service_id;
        }
      }
      setTimeout(async () => {
        await this.initializeFacilityServices();
        await this.getTimings();
      }, 10);
    },
    async getTimings() {
      this.generateTiming();
      await this.getTimingTemplates();
    },
    async getTimingTemplates() {
      this.showLoader("Loading");
      await this.$store
          .dispatch("loadTimingTemplatesByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let templates = response.data.data;
              this.timingTemplates = JSON.parse(
                  JSON.stringify(templates.filter((i) => i.is_default === 0))
              );
              if (templates.filter((i) => i.is_default === 1).length > 0) {
                this.defaultTiming = templates.filter((i) => i.is_default === 1)[0];
              } else {
                this.defaultTiming = {
                  name: null,
                  weekdays: [],
                  start_time: null,
                  end_time: null,
                };
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    goToTimings() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityTiming",
        params: {
          data: btoa(JSON.stringify({venue_service_id: this.venueServiceId})),
        },
      });
      this.getTimings();
    },
    deleteTimingTemplate(index) {
      if (this.timingTemplates[index].id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this templates?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_timing_template",
        };
      } else {
        this.timingTemplates.splice(index, 1);
      }
    },
    confirmActions(data) {
      if (data.type == 'delete_timing_template') {
        this.deleted_timing_templates.push(this.timingTemplates[data.id].id);
        this.timingTemplates.splice(data.id, 1);
      }
      this.confirmModel.id = null;
    },
    saveTimings() {
      if (!this.$refs.conf_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }

      let formData = new FormData();
      formData.append(`venue_service_id`, this.venueServiceId);


      for (let key in this.defaultTiming) {
        formData.append(
            `default_timing[0][${key}]`,
            typeof this.defaultTiming[key] != "object"
                ? this.defaultTiming[key]
                : JSON.stringify(this.defaultTiming[key])
        );
      }



      if (this.deleted_timing_templates.length) {
        this.deleted_timing_templates.forEach((item, index) => {
          formData.append(`deleted_timing_templates[${index}]`, item);
        });
      }

      this.timingTemplates.forEach((template, index) => {
        for (let key in template) {
          formData.append(
              `timing_templates[${index}][${key}]`,
              typeof template[key] != "object"
                  ? template[key]
                  : JSON.stringify(template[key])
          );
        }
        formData.set(
            `timing_templates[${index}][venue_service_id]`,
            this.venueServiceId
        );
      });
      this.showLoader('Saving..');
      this.$http({
        method: "post",
        data: formData,
        url: "venues/facilities/save-timing",
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
          .then((response) => {
            if (response.status == 200) {
              this.$store.dispatch("loadVenueServices");
              this.$store
                  .dispatch(
                      "loadConfigurationsByVenueServiceId",
                      this.venueServiceId
                  )
                  .then(() => {
                    this.$nextTick(() => {
                      this.$forceUpdate();
                    });
                  });
              this.getTimings();
              this.hideLoader();
              this.showSuccess("Configuration successfully updated");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
            this.$store.dispatch(
                "loadConfigurationsByVenueServiceId",
                this.venueServiceId
            );
            this.$store.dispatch("loadVenueServices");
          });
    },
    icon() {
      if (this.weekdays.length == this.defaultTiming.weekdays.length)
        return "mdi-close-box";
      if (this.defaultTiming.weekdays.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    toggle() {
      this.$nextTick(() => {
        if (this.weekdays.length == this.defaultTiming.weekdays.length) {
          this.defaultTiming.weekdays = [];
        } else {
          this.defaultTiming.weekdays = this.weekdays.map(
              (item) => item.bit_value
          );
          this.$forceUpdate();
        }
      });
    },
    iconTemplate(index) {
      if (this.weekdays.length == this.timingTemplates[index].weekdays.length)
        return "mdi-close-box";
      if (this.timingTemplates[index].weekdays.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    toggleTemplate(index) {
      this.$nextTick(() => {
        if (this.weekdays.length == this.timingTemplates[index].weekdays.length) {
          this.timingTemplates[index].weekdays = [];
        } else {
          this.timingTemplates[index].weekdays = this.weekdays.map(
              (item) => item.bit_value
          );
          this.$forceUpdate();
        }
      });
    },
  }
}
</script>

<style scoped>

</style>